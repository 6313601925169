import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:id/pay',
    name: 'Pay',
    props: true,
    component: () => import('../views/Pay.vue')
  },
  {
    path: '/:id/redirect',
    name: 'Redirect',
    props: true,
    component: () => import('../views/Redirect.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: 'Not found',
    component: () => import('../views/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
